import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { Lista_log_login_modelo_response } from 'src/model/Catalogos/Generales';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.css'],
  providers: [ServiceGenerico]
})
export class BitacoraComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  listaLog: Lista_log_login_modelo_response[];

  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
   }
  ngOnInit() {
    this.inicializaTabla();
    this.obtenerLogs();
 }

 inicializaTabla() {
  this.dtOptions = this.themeConstants.dtOptions;
  this.dtTrigger = new Subject();
}

renderTabla() {
  if ("dtInstance" in this.dtElement) {
    this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
      instancia.destroy();
      this.dtTrigger.next();
    });
  } else {
    this.dtTrigger.next();
  }
}

obtenerLogs() {
  this.operacionRespuesta.EstaEjecutando = true;
  this.services
    .HttpGet(
      this.modelo_configuracion.serviciosOperaciones + "/Generales/GetLogLogin?p_cat_estado_id=1")
    .subscribe(
      (tempdate) => {
        if (tempdate) {
          this.listaLog = [] = tempdate.response as Lista_log_login_modelo_response[];
          this.renderTabla();
        } else {
          this.listaLog = [];
          this.renderTabla();
        }
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async (err) => {
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
}
}
