import { Time } from "@angular/common";
import { Timestamp } from "rxjs/internal/operators/timestamp";

export class Lista_colaboradores_modelo_response {
     tbl_usuario_id: number;
      tbl_persona_id: number; 
      nombre: string; 
      apellido_paterno: string; 
      apellido_materno: string; 
      numero_empleado: string; 
      es_alergico:boolean; 
      estatus:boolean; 
}
export class Lista_usuarios_sistema_modelo_response {
     tbl_usuario_id: number;
      tbl_persona_id: number; 
      nombre: string; 
      apellido_paterno: string; 
      apellido_materno: string; 
      tbl_perfil_id: string; 
      nombre_perfil: string; 
      estatus:boolean; 
}

export class Colaborador_modelo_response {
    tbl_persona_id: number;
    tbl_usuario_id: number;
    nombre: string;
    apellido_paterno: string;
    apellido_materno: string;
    correo: string
    telefono: number;
    curp: string;
    fecha_nacimiento: Date;
    numero_empleado: number;
    es_alergico: boolean;
    requiere_pin: boolean;
    pin: string;
    tbl_direccion_id: number;
    calle: string;
    numero_ext: string;
    numero_int: string;
    cpostal: number;
    nombre_col: string;
    nombre_municipio: string;
    nombre_estado: string;
    nombre_pais: string;
    cat_cpostal_id: number;
    cat_municipio_id: number;
    cat_estado_id: number;
}
export class Usuario_sistema_modelo_response {
    tbl_persona_id: number;
    tbl_usuario_id: number;
    nombre: string;
    apellido_paterno: string;
    apellido_materno: string;
    correo: string
    telefono: number;
}

export class info_usuario_modelo_response
{
    tbl_usuario_id: number;
    tbl_persona_id: number;
     nombre: string;
     apellido_paterno: string;
     apellido_materno: string;
     es_alergico: boolean;
     requiere_pin: boolean;
     pin: string;
     super_usuario: boolean;
     numero_empleado: string;
    rel_usuario_horario_id: number;
     nombre_tipo_comida:string;
     lunes:boolean;
     h_lunes_i:Time;
     h_lunes_f:Time;
     martes:boolean;
     h_martes_i:Time;
     h_martes_f:Time;
     miercoles:boolean;
     h_miercoles_i:Time;
     h_miercoles_f:Time;
     jueves:boolean;
     h_jueves_i:Time;
     h_jueves_f:Time;
     viernes:boolean;
     h_viernes_i:Time;
     h_viernes_f:Time;
     sabado:boolean;
     h_sabado_i:Time;
     h_sabado_f:Time;
     domingo:boolean;
     h_domingo_i:Time;
     h_domingo_f:Time;
     tbl_comida_reclamada_id: number;
  length: number;
}

export class arr_horarios_colaborador {
   nombre_comida: string;
   horario: string;
   estatus_reclamo: number; 
}

export class arr_horarios_colaborador_disponible {
    rel_usuario_horario_id: number;
    nombre_comida: string;
 }

 export class arr_Img_Colaborador {
    imgName:string;
    archivo: string;
    extension:string;
 }