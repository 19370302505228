import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})
export class TicketComponent implements OnInit {
  infoComensal: string;
  nombreComensal: string;
  nombreComida: string;
  id: any;
  fechaHora: any;

  constructor(private route: ActivatedRoute) {

   }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
   
    const idDecodificado = atob(this.id);
const idDecode = decodeURIComponent(idDecodificado);
const partes = idDecode.split('_');

this.nombreComensal = partes[0];
this.nombreComida = partes[1];

const fechaHoraActual = new Date();

// Formatear la fecha y hora con dos dígitos para el mes y el día
const fechaFormateada = fechaHoraActual.toLocaleString('es-ES', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});
const horaFormateada = fechaHoraActual.toLocaleString('es-ES', {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});
this.fechaHora = fechaFormateada + ' '+ horaFormateada;

this.imprimir();

  // Agregar el evento onfocus para cerrar la ventana cuando el usuario regrese desde la ventana de impresión
  window.onfocus = () => {
    setTimeout(() => {
      window.close();
    }, 50); // Cerrar automáticamente después de 2 segundos, ajusta el tiempo según tus necesidades
  };
}

imprimir() {

  window.print();

}


}
